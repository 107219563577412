import React from 'react';
import { Layout } from "../components/layout";
import { CTA } from "../components/partials/cta";

import commonFunctions from "../helpers/commonFunctions";

import { graphql } from 'gatsby'
import get from 'lodash/get'

import ArrowRight from '../assets/img/svg/shared/arrow-right-green.svg';

import "../assets/scss/pages/templated-page.scss";
class ImageTextPageTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulPageImageTextPage')

    return (
      <Layout
        breadcrumbs={[commonFunctions.parseDescription(post.title)]}
        metaTagArray={post.metaTags}>
        <div className="templated-page">
          <div className="container d-block d-sm-flex align-items-center justify-content-center hero-section easy-use">
            <div className="d-block d-sm-flex align-items-center justify-content-between content">
              <div>
                <h1>{post.mainImageTextLink.header}</h1>
                <div className="sub-text-2" dangerouslySetInnerHTML={{ __html: post.mainImageTextLink.description.childMarkdownRemark.html }}></div>
                <a href={post.mainImageTextLink.link ? commonFunctions.parseDescriptionAndUrl(post.mainImageTextLink.link.childMarkdownRemark.html) : '#'}
                  target="_blank" className="btn btn-primary button-text">
                  {post.mainImageTextLink.linkText}
                </a>
              </div>
              <img src={post.mainImageTextLink.image ? post.mainImageTextLink.image.file.url : ''} alt="hero img" width="476px" height="465px" />
            </div>
          </div>

          {post.imageTextLinks.map((imageTextLinkItem, imageTextLinkIndex) => {
            if (imageTextLinkIndex % 2 != 0) {//odd
              return (
                <div className="container d-flex align-items-center img-text-section">
                  <div className="d-flex align-items-center content">
                    <div className="copy-container">
                      <h2 className="sub-text-1">{imageTextLinkItem.header}</h2>
                      <div className="body-text-1" dangerouslySetInnerHTML={{ __html: imageTextLinkItem.description.childMarkdownRemark.html }}></div>
                      <a href={imageTextLinkItem.link ? commonFunctions.parseDescriptionAndUrl(imageTextLinkItem.link.childMarkdownRemark.html) : '#'} target="_blank" className="learn-more-btn">{imageTextLinkItem.linkText}<img src={ArrowRight} alt="arrow right" /></a>
                    </div>
                    <div className="d-flex align-items-center justify-content-center img-container">
                      {imageTextLinkItem.image && <img class="main-img jpeg" src={imageTextLinkItem.image ? imageTextLinkItem.image.file.url : ''} alt="jpeg image" width="100%" height="auto" />}
                    </div>
                  </div>
                </div>
              )
            }
            else {
              return (
                <div className="container d-flex align-items-center img-text-section">
                  <div className="d-flex align-items-center content">
                    <div className="d-flex align-items-center justify-content-start img-container">
                      {imageTextLinkItem.image && <img class="main-img jpeg" src={imageTextLinkItem.image ? imageTextLinkItem.image.file.url : ''} alt="jpeg image" width="100%" height="auto" />}
                    </div>
                    <div className="copy-container">
                      <h2 className="sub-text-1">{imageTextLinkItem.header}</h2>
                      <div className="body-text-1" dangerouslySetInnerHTML={{ __html: imageTextLinkItem.description.childMarkdownRemark.html }}></div>
                      <a href={imageTextLinkItem.link ? commonFunctions.parseDescriptionAndUrl(imageTextLinkItem.link.childMarkdownRemark.html) : '#'} target="_blank" className="learn-more-btn">{imageTextLinkItem.linkText}<img src={ArrowRight} alt="arrow right" /></a>
                    </div>
                  </div>
                </div>
              )
            }
          })}

        </div>

      </Layout >
    )
  }
}

export default ImageTextPageTemplate

export const pageQuery = graphql`
  query ImageTextPageBySlug($slug: String!) {
    contentfulPageImageTextPage(slug: { eq: $slug }) {
        metaTags{
            name
            content
          }
          title
          imageTextLinks{
            header
            linkText
            link{
              childMarkdownRemark {
                html
              }
            }
            description{
              childMarkdownRemark {
                html
              }
            }
            image{
              file{
                url
              }
            }
          }
          slug
          mainImageTextLink{
            header
            linkText
            link{
              childMarkdownRemark {
                html
              }
            }
            description{
              childMarkdownRemark {
                html
              }
            }
            image{
              file{
                url
              }
            }
          }
    }
  }
`
